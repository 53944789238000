body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box-fullwith{
	width: 100%;
}

.microbox-badge {
  border-style: solid;
  border-width: 1px;
  border-radius: 0.16rem;
  border-color: #0080e2;
  font-weight: 700;
  padding: .16em .16em;
  padding-top: .08em;
}